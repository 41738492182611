import { CountUp } from 'countup.js';

document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--stats');
	const stats = document.querySelectorAll('.stat .value');
	const config = { rootMargin: '0px 0px',	threshold: 0 };

	const block_stats = {
		
		statIntersection: (entries) => {
			
			entries.forEach( entry => {
				if ( entry.intersectionRatio > 0 ) {
					statObserver.unobserve( entry.target );
					block_stats.count( entry.target );
				}
			});
		},
		
		blockIntersection: (entries) => {
			
			entries.forEach( entry => {
				if ( entry.intersectionRatio > 0 ) {
					blockObserver.unobserve( entry.target );
					block_stats.animate( entry.target );
				}
			});		
		},
		
		count: (value) => {

			const num = value.dataset.value;
			const decimals = value.dataset.decimals;
			const target = value.id;
			const options = {
				prefix: value.dataset.prefix,
				suffix: value.dataset.suffix,
				duration: 3,
				decimalPlaces: (typeof decimals === 'undefined') ? 0 : decimals
			}
			const countUp = new CountUp( target, num, options);
			countUp.start();
		},
		
		animate: (block) => {
			block.classList.add('animate');
			
			setTimeout(function() {
				stats.forEach( stat => {
					statObserver.observe( stat );
				});
			}, 1000);
		},
		
		log: () => {
			console.log('test');
		}
	}

	let statObserver = new IntersectionObserver( block_stats.statIntersection, config );

	let blockObserver = new IntersectionObserver( block_stats.blockIntersection, config );
	blocks.forEach( block => {
		blockObserver.observe( block );
	});
});